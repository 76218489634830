.swiper-wrapper {
  display: flex;
  align-items: center;
  .swiper-slide {
    figure {
      cursor: pointer;
      .image {
        -webkit-filter: grayscale(100%) brightness(70%);
        filter: grayscale(100%) brightness(70%);
        opacity: 0.4;
        -webkit-transition: 0.2s ease-in-out;
        transition: 0.2s ease-in-out;
      }
      &:hover {
        .image {
          -webkit-filter: grayscale(0%) brightness(100%)
            drop-shadow(0px 0px 0px #f5f5f5);
          filter: grayscale(0%) brightness(100%)
            drop-shadow(0px 0px 0px #f5f5f5);
          opacity: 1;
        }
      }
    }
  }
}

.swiper-button-next {
  position: absolute;
  right: 0px;
  color: black;
  &:after {
    content: "";
  }
}

.swiper-button-prev {
  left: 0px;
  position: absolute;
  color: black;
  &:after {
    content: "";
  }
}

.partner-swiper-next {
  position: absolute;
  right: 0px;
  color: white;
  &:after {
    content: "";
  }
}

.partner-swiper-prev {
  left: 0px;
  position: absolute;
  color: white;
  &:after {
    content: "";
  }
}
